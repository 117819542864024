<template>
    <div class="nav">
        <div class="header">
            {{clicklabel}}
        </div>
        <el-tree
            ref="tree"
            node-key="id"
            :highlight-current='true'
            :data="treeData" @node-click="open">
        </el-tree>
    </div>
</template>

<script>
import { request } from '@/assets/js/http.js'
export default {
  name: 'SideMenu',
  data: function () {
    var treeData
    if (!this.$store.state.SideMenuData.label) {
      for (let s = 0; s < this.$store.state.MenuData.length; s++) {
        // eslint-disable-next-line eqeqeq
        if (this.$store.state.MenuData[s].alias == this.$route.params.frameName) {
          treeData = this.$store.state.MenuData[s].children
        }
      }
    } else {
      treeData = this.$store.state.SideMenuData.children
    }
    return {
      clicklabel: '',
      treeData: treeData
    }
  },
  watch: {
    '$store.state.SideMenuData': function () {
      if (!this.$store.state.SideMenuData.label) {
        for (let s = 0; s < this.$store.state.MenuData.length; s++) {
          // eslint-disable-next-line eqeqeq
          if (this.$store.state.MenuData[s].alias == this.$route.params.frameName) {
            this.treeData = this.$store.state.MenuData[s].children
            this.$nextTick(function () {
              this.$refs.tree.setCurrentKey(this.$store.state.MenuData[s].defaultSelectId)
              var nodeData = this.$refs.tree.getCurrentNode()
              if (nodeData != null) {
                request('/api/system/menu/getButtonByMenuId/' + nodeData.id, 'post', {}).then((response) => {
                  nodeData.menuData = response.data
                  this.$store.commit('setViewMenuData', nodeData)
                })
              }
            })
          }
        }
      } else {
        this.treeData = this.$store.state.SideMenuData.children
        this.$nextTick(function () {
          this.$refs.tree.setCurrentKey(this.$store.state.SideMenuData.defaultSelectId)
          var nodeData = this.$refs.tree.getCurrentNode()
          if (nodeData != null) {
            request('/api/system/menu/getButtonByMenuId/' + nodeData.id, 'post', {}).then((response) => {
              nodeData.menuData = response.data
              this.$store.commit('setViewMenuData', nodeData)
            })
          }
        })
      }
    },
    '$store.state.MenuData': function () {
      if (!this.$store.state.SideMenuData.label) {
        for (let s = 0; s < this.$store.state.MenuData.length; s++) {
          // eslint-disable-next-line eqeqeq
          if (this.$store.state.MenuData[s].alias == this.$route.params.frameName) {
            this.treeData = this.$store.state.MenuData[s].children
            this.$nextTick(function () {
              if (this.$route.params.viewName) {
                var vid = ''
                for (var i in this.treeData) {
                  if (this.treeData[i].alias === this.$route.params.viewName) {
                    vid = this.treeData[i].id
                    break
                  }
                  if (this.treeData[i].children.length > 0) {
                    for (var v in this.treeData[i].children) {
                      if (this.treeData[i].children[v].alias === this.$route.params.viewName) {
                        vid = this.treeData[i].children[v].id
                        break
                      }
                    }
                  }
                }
                this.$refs.tree.setCurrentKey(vid)
              } else {
                this.$refs.tree.setCurrentKey(this.$store.state.MenuData[s].defaultSelectId)
              }
              var nodeData = this.$refs.tree.getCurrentNode()
              if (nodeData != null) {
                request('/api/system/menu/getButtonByMenuId/' + nodeData.id, 'post', {}).then((response) => {
                  nodeData.menuData = response.data
                  this.$store.commit('setViewMenuData', nodeData)
                })
              }
            })
          }
        }
      } else {
        this.treeData = this.$store.state.SideMenuData.children
        this.$nextTick(function () {
          this.$refs.tree.setCurrentKey(this.$store.state.SideMenuData.defaultSelectId)
          var nodeData = this.$refs.tree.getCurrentNode()
          if (nodeData != null) {
            request('/api/system/menu/getButtonByMenuId/' + nodeData.id, 'post', {}).then((response) => {
              nodeData.menuData = response.data
              this.$store.commit('setViewMenuData', nodeData)
            })
          }
        })
      }
    }
  },
  mounted: function () {
    this.$nextTick(function () {
      if (!this.$store.state.SideMenuData.label) {
        for (let s = 0; s < this.$store.state.MenuData.length; s++) {
        // eslint-disable-next-line eqeqeq
          if (this.$store.state.MenuData[s].alias == this.$route.params.frameName) {
            this.$refs.tree.setCurrentKey(this.$store.state.MenuData[s].defaultSelectId)
          }
        }
      } else {
        this.$refs.tree.setCurrentKey(this.$store.state.SideMenuData.defaultSelectId)
      }
      var nodeData = this.$refs.tree.getCurrentNode()
      if (nodeData != null) {
        request('/api/system/menu/getButtonByMenuId/' + nodeData.id, 'post', {}).then((response) => {
          nodeData.menuData = response.data
          this.$store.commit('setViewMenuData', nodeData)
        })
      }
    })
  },
  methods: {
    open: function (data) {
      if (data.openWay === 1) {
        request('/api/system/menu/getButtonByMenuId/' + data.id, 'post', {}).then((response) => {
          data.menuData = response.data
          this.$store.commit('setViewMenuData', data)
        })
        this.$router.push({ name: 'frame', params: { viewName: data.alias } })
        this.$emit('OpenView', data)
      }
      if (data.openWay === 2) {
        window.open(data.url)
      }
    }
  }
}
</script>

<style scoped>
.nav{
    width: 200px;
    flex-grow: 0;
    overflow: auto;
    background: #fff;
  }
.nav .header{
      height: 40px;
      color: #fff;
      font-size: 14px;
      line-height: 40px;
      padding-left: 20px;
      display: none;
  }
.nav .el-tree{
      background:none;
      color: #333;
  }
.nav :deep(.is-current>.el-tree-node__content:before){
          content: " ";
          display: inline-block;
          width: 2px;
          background: #4487fa;
          vertical-align: text-top;
          margin-right: 5px;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
        }
  .nav :deep(.is-current>.el-tree-node__content){
    position: relative;
  }
.nav :deep(.el-tree-node__content){
  height: 40px;
  }
.nav :deep(.el-tree-node__content:hover){
  background-color:rgba(68, 135, 250, 0.2);
  color: #4487FA;
 }
.nav :deep(.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content){
  background-color: rgba(68, 135, 250, 0.2);
  color:#4487FA;
 }
 .nav :deep(.el-tree-node .el-tree-node__label){
    font-size: 16px;
  }
</style>
